export function validatePassword (password) {
    if (password !== '') {
        const uppercaseLetter = password.match(/[A-Z]/g)
        const number = password.match(/[0-9]/g)
        if (uppercaseLetter !== null && number !== null) {
            return uppercaseLetter.length > 0 && password.length > 7 && number.length > 0
        } else {
            return false
        }
    } else {
        return true
    }
}

export const alphaToNum = {
    a: "1",
    b: "2",
    c: "3",
    d: "4",
    e: "5",
    f: "6",
    g: "7",
    h: "8",
    i: "9"
}

export const paymentsType = [
    {
        label: "1 MESE - 6,99€",
        value: 6.99,
        id: "1",
        item_number: "sub_one_month",
        item_name: "1 MESE",
    },
    {
        label: "3 MESI - 13,99€",
        value: 13.99,
        id: "2",
        item_number: "sub_three_months",
        item_name: "3 MESI",
    },
    /*{
        label: "6 MESI - 17,99€",
        value: 17.99,
        id: "2",
        item_number: "sub_six_months",
        item_name: "6 MESI",
    },*/
    {
          label: "1 ANNO - 24,99€",
          value: 24.99,
          id: "3",
          item_number: "sub_one_year",
          item_name: "1 ANNO",
      }
]

//#region ---- Gestione cambio tab
// Doc: https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API

let hidden;
let visibilityChange;
if (typeof document.hidden !== "undefined") { // Opera 12.10 and Firefox 18 and later support
    hidden = "hidden";
    visibilityChange = "visibilitychange";
} else if (typeof document.msHidden !== "undefined") {
    hidden = "msHidden";
    visibilityChange = "msvisibilitychange";
} else if (typeof document.webkitHidden !== "undefined") {
    hidden = "webkitHidden";
    visibilityChange = "webkitvisibilitychange";
}

export function registerVisibilityChange(fn, options) {
    window.addEventListener(visibilityChange, fn, options)
}

export function unregisterVisibilityChange(fn) {
    window.removeEventListener(visibilityChange, fn);
}

export function isDocumentHidden() {
    return document[hidden];
}

//#endregion
