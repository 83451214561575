<template>
    <div id="subscription">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-7">
                        <img class="mb-3" src="../assets/images/new-image/premium.jpg" width="100%">
                        <h3><strong>{{$t('subscription.acquistaPremium')}}</strong></h3>
                        <p>
                            {{$t('subscription.negliAnni')}}
                        </p>
                        <h4><strong>{{$t('subscription.vantaggi')}}</strong></h4>

                        <div class="accordion custom-accordion" id="accordionExample">
                            <div class="card shadow-none mb-2">
                                <a data-toggle="collapse" href="#collapseOne" class="collapsed faq" aria-expanded="true" aria-controls="collapseOne">
                                    <div class="card-header" id="headingOne">
                                        <h6 class="mb-0 faq-question" style="display: flex; align-items: center;">
                                            <i class="mdi mdi-help text-primary h5 mr-3" style="margin-bottom: 0;"></i>{{$t('subscription.potroEsercitarmi')}}
                                            <i class="mdi mdi-chevron-up float-right accor-plus-icon" style="margin-left: auto;"></i>
                                        </h6>
                                    </div>
                                </a>

                                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="text-muted mb-0 faq-ans">{{$t('subscription.potroEsercitarmiRisposta')}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- collapse one end -->

                            <div class="card shadow-none mb-2">
                                <a class="collapsed faq" data-toggle="collapse" href="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <div class="card-header" id="headingTwo">
                                        <h6 class="mb-0 faq-question" style="display: flex; align-items: center;">
                                            <i class="mdi mdi-help text-primary h5 mr-3" style="margin-bottom: 0;"></i>{{$t('subscription.app')}}
                                            <i class="mdi mdi-chevron-up float-right accor-plus-icon" style="margin-left: auto;"></i>
                                        </h6>
                                    </div>
                                </a>

                                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="text-muted mb-0 faq-ans">{{$t('subscription.appRisposta')}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- collapse two end -->

                            <div class="card shadow-none mb-2">
                                <a class="collapsed faq" data-toggle="collapse" href="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                    <div class="card-header" id="headingThree">
                                        <h6 class="mb-0 faq-question" style="display: flex; align-items: center;">
                                            <i class="mdi mdi-help text-primary h5 mr-3" style="margin-bottom: 0;"></i>{{$t('subscription.ulterioriVantaggi')}}
                                            <i class="mdi mdi-chevron-up float-right accor-plus-icon" style="margin-left: auto;"></i>
                                        </h6>
                                    </div>
                                </a>

                                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="text-muted mb-0 faq-ans">{{$t('subscription.ulterioriVantaggiRisposta')}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- collapse two end -->
                            <div class="card shadow-none mb-2" v-show="false">
                                <a class="collapsed faq" data-toggle="collapse" href="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                    <div class="card-header" id="headingFour">
                                        <h6 class="mb-0 faq-question" style="display: flex; align-items: center;">
                                            <i class="mdi mdi-help text-primary h5 mr-3" style="margin-bottom: 0;"></i>{{$t('subscription.pagamento')}}
                                            <i class="mdi mdi-chevron-up float-right accor-plus-icon" style="margin-left: auto;"></i>
                                        </h6>
                                    </div>
                                </a>

                                <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="text-muted mb-0 faq-ans">{{$t('subscription.pagamentoRisposta')}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- collapse two end -->
                            <div class="card shadow-none mb-2">
                                <a class="collapsed faq" data-toggle="collapse" href="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                    <div class="card-header" id="headingFive">
                                        <h6 class="mb-0 faq-question" style="display: flex; align-items: center;">
                                            <i class="mdi mdi-help text-primary h5 mr-3" style="margin-bottom: 0;"></i>{{$t('subscription.comePagare')}}
                                            <i class="mdi mdi-chevron-up float-right accor-plus-icon" style="margin-left: auto;"></i>
                                        </h6>
                                    </div>
                                </a>

                                <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
                                    <div class="card-body">
                                        <p class="text-muted mb-0 faq-ans">{{$t('subscription.comePagareRisposta')}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- collapse two end -->
                        </div>
                    </div>
                    <div class="col-5 text-center">
                        <div class="card-body bg-light">
                            <h4 class="mt-3"><strong>PREMIUM</strong></h4>
                            <p class="text-muted">{{$t('subscription.contenutiDisponibili')}}</p>
                            <p class="text-muted">{{$t('subscription.acquistaLaVersionePremium')}}</p>
                            <img class="mb-3" src="../assets/images/new-image/quality.png" width="64">
                            <div class="plan-features text-muted mt-3">
                                <!--p><i style="color:#2fa97c" class="mdi mdi-shield-check-outline mr-2 mdi-24px align-middle "></i>Quiz illimitati</p-->
                                <p>{{$t('subscription.quizIllimitati')}}</p>
                                <p>{{$t('subscription.vantaggiEsclusivi')}}</p>
                                <p>{{$t('subscription.materialeDidattico')}}</p>
                                <p>{{$t('subscription.aggiornamentiRealTime')}}</p>
                                <p>{{$t('subscription.accediQuandoVuoi')}}</p>
                            </div>

                            <div class="mt-5">
                                <div class="mt-5 mb-3">
                                    <p>{{$t('subscription.durataPremium')}}</p>
                                    <pay-pal-payment></pay-pal-payment>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PayPalPayment from "@/components/PayPalPayment";
import {routesEnums} from "@/helpers/enums";
import {mapActions, mapGetters} from "vuex";
import {MODULES} from "@/store/modules-enum";
import {AUTH_GETTERS} from "@/store/auth/getters-enum";
import firebase from "firebase";
import {PROFILE_ACTIONS} from "../store/profile/actions-enum";
import _ from "lodash";
import BrevoClient from "../lib/CustomConcorsandoClient/api/BrevoClient";

const brevoClient = BrevoClient.instance

export default {
    name: "subscription",
    components: {PayPalPayment},
    mounted() {
        this.isAccountPremium()
        this.contacts();
        firebase.analytics().logEvent('sales_page_shown')
        window.fbq('trackCustom', 'sales_page_shown')
    },
    computed: {
        ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE]),
    },
    methods: {
        isAccountPremium () {
            if (this.profile.livello <= 1) {
                this.$router.push({ name: routesEnums.HOME })
            } else {
                this.backToTop()
            }
        },
        backToTop () {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        contacts() {
            brevoClient.postContacts({
                ext_id: this.profile.id_utente_concorsando?.toString(),
                premium: this.isPremium(),
                email: this.profile.email_comunicazioni,
            }).then(() => {
                console.log('success')
            })
                .catch(() => {
                    console.log('error')
                })
        },
        isPremium: function () {
            return _.get(this.profile, "livello") <= 1;
        },
    }
}
</script>

<style scoped>

</style>
